// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/Faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-furniture-delivery-js": () => import("./../../../src/pages/FurnitureDelivery.js" /* webpackChunkName: "component---src-pages-furniture-delivery-js" */),
  "component---src-pages-heavy-lifting-moving-js": () => import("./../../../src/pages/HeavyLiftingMoving.js" /* webpackChunkName: "component---src-pages-heavy-lifting-moving-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/Home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-junk-removal-js": () => import("./../../../src/pages/JunkRemoval.js" /* webpackChunkName: "component---src-pages-junk-removal-js" */),
  "component---src-pages-lee-summit-movers-js": () => import("./../../../src/pages/Lee-summit-movers.js" /* webpackChunkName: "component---src-pages-lee-summit-movers-js" */),
  "component---src-pages-local-moving-js": () => import("./../../../src/pages/LocalMoving.js" /* webpackChunkName: "component---src-pages-local-moving-js" */),
  "component---src-pages-locations-js": () => import("./../../../src/pages/Locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-long-distance-moving-js": () => import("./../../../src/pages/LongDistanceMoving.js" /* webpackChunkName: "component---src-pages-long-distance-moving-js" */),
  "component---src-pages-overland-park-movers-js": () => import("./../../../src/pages/Overland-park-movers.js" /* webpackChunkName: "component---src-pages-overland-park-movers-js" */),
  "component---src-pages-packing-service-js": () => import("./../../../src/pages/PackingService.js" /* webpackChunkName: "component---src-pages-packing-service-js" */),
  "component---src-pages-quote-js": () => import("./../../../src/pages/Quote.js" /* webpackChunkName: "component---src-pages-quote-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/Reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/Services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

